<template>
  <Popup
    id="register-form"
    :closeButton="closeButton"
    :closeOnClickOutside="closeOnClickOutside"
    @close="closeModal"
    v-show="opened"
    customClass="z-30"
  >
    <template slot="header">
      <Label
        v-if="register"
        :uppercase="false"
        class="popup-header-title-label"
        color="primary"
        bold="normal"
        size="small"
      >
        {{ popupTitleRegister }}
      </Label>
      <Label
        class="popup-header-title-label"
        v-if="login"
        :uppercase="false"
        color="primary"
        bold="normal"
        size="small"
      >
        {{ popupTitleLogin }}
      </Label>
      <Label
        class="popup-header-title-label"
        v-if="recoverPasswordPopup"
        :uppercase="false"
        color="primary"
        bold="normal"
        size="small"
      >
        {{ popupTitleRecoverPassword }}
      </Label>
      <Label
        class="popup-header-title-label"
        v-if="passwordSent"
        :uppercase="false"
        color="primary"
        bold="normal"
        size="small"
      >
        {{ popupTitlePasswordSent }}
      </Label>
      <Label
        class="popup-header-title-label"
        v-if="loginMyHyundai"
        :uppercase="false"
        color="primary"
        bold="normal"
        size="small"
      >
        {{ popupTitleLoginMyHyundai }}
        <span>{{ popupTitleMyHyundai }}</span>
      </Label>
    </template>
    <template slot="body">
      <!-- SectionHeader -->
      <div class="register-form--header">
        <div v-if="recoverPasswordPopup" class="recover-password--content">
          <div class="recover-password--header">
            <Label color="primary" size="small"
              >{{ headerSubtitleRecoverPassword }}
            </Label>
          </div>

          <div class="recover-password--input">
            <div class="register-form--buttons">
              <InputForm
                type="primary"
                inputType="text"
                :label="$t('placeholder.email') + ' *'"
                bgColor="#F5F7F9"
                @onChange="
                  value => {
                    myPersonalEmail = value;
                  }
                "
              />
              <Label
                v-if="errorEmail"
                color="tertiary"
                bold="thin"
                size="small"
              >
                {{ errorEmail }}</Label
              >
            </div>
          </div>
          <div class="recover-password--button">
            <div class="register-form--buttons">
              <Button
                class="mb-5"
                type="primary"
                :fill="true"
                :border="false"
                :label="$t('popup.auth.recoverPassword.labelButton')"
                :uppercase="true"
                @onClick="clickRecoverPassword"
                :disabled="recoverButtonEnabled"
              ></Button>
            </div>
          </div>
          <div class="recover-password--footer">
            <div>
              <Label color="primary" size="small">{{
                $t("popup.auth.recoverPassword.remember")
              }}</Label>
            </div>
            <div>
              <Link
                class="register-form--header-subtitle-link"
                :label="headerLinkRegister"
                size="small"
                color="secondary"
                @onClick="clickLogin"
              />
            </div>
          </div>
        </div>
        <div v-if="passwordSent" class="recover-password--content">
          <div class="password-sent--header">
            <Label color="primary" size="small"
              >{{ $t("popup.auth.recoverPassword.passwordSentDescription") }}
            </Label>
          </div>
          <div class="sent-password--button">
            <div class="register-form--buttons">
              <Button
                class="mb-5"
                type="primary"
                :fill="true"
                :border="false"
                :label="$t('popup.auth.recoverPassword.labelButtonClose')"
                :uppercase="true"
                @onClick="closeModal"
              ></Button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="chooseRegisterType && login" class="register-form--body">
        <div
          class="register-form--header-subtitle-description"
          style="display:none"
        >
          <Label color="primary" size="medium">{{
            $t("popup.auth.login.title")
          }}</Label>
        </div>
        <div class="register-form--buttons" style="display:none">
          <Button
            type="primary"
            :fill="true"
            :border="false"
            :label="$t('popup.auth.login.labelFacebook')"
            :uppercase="true"
            bgColor="#3b5998"
            customClass="facebook-button"
            icon="facebook-f"
            iconPrefix="fab"
            @onClick="facebookLogin"
          ></Button>
        </div>
        <div class="register-form--buttons" style="display:none">
          <Button
            type="primary"
            :fill="true"
            :label="$t('popup.auth.login.labelGoogle')"
            :border="false"
            :uppercase="true"
            bgColor="#E26A61"
            customClass="facebook-button"
            icon="google"
            iconPrefix="fab"
            @onClick="googleLogin"
          ></Button>
        </div>
        <div class="register-form--buttons">
          <Button
            type="primary"
            :fill="true"
            :label="$t('popup.auth.register.labelMyHyundai')"
            :uppercase="true"
            bgColor="#A36B4F"
            customClass="hyundai-button"
            filename="myHyundai.png"
            iconPrefix="fab"
            @onClick="myHyundayLogin"
          ></Button>
        </div>
        <div class="register-form--separator">
          <Label type="primary" size="small" uppercase>{{
            $t("popup.auth.register.or")
          }}</Label>
        </div>
        <div class="register-form--separator" style="display:none">
          <Label type="primary" size="small">{{
            $t("popup.auth.login.titleEmail")
          }}</Label>
        </div>
        <div class="register-form--buttons">
          <InputForm
            type="primary"
            inputType="text"
            :label="$t('placeholder.email') + ' *'"
            bgColor="#F5F7F9"
            @onChange="
              value => {
                myPersonalEmail = value;
              }
            "
          />
          <Label v-if="errorEmail" color="tertiary" bold="thin" size="small">
            {{ errorEmail }}</Label
          >
        </div>
        <div class="register-form--buttons">
          <InputForm
            type="primary"
            inputType="password"
            :label="$t('placeholder.password') + ' *'"
            bgColor="#F5F7F9"
            @onChange="
              value => {
                myPersonalPassword = value;
              }
            "
          />
          <Label
            v-if="errorPassword != null || showInfoMessage"
            color="tertiary"
            bold="thin"
            size="small"
          >
            {{ errorPassword }}</Label
          >
        </div>
        <div class="register-form--buttons">
          <Button
            class="mb-5"
            type="primary"
            :fill="true"
            :border="false"
            :label="$t('popup.auth.login.labelEmail')"
            :uppercase="true"
            @onClick="clickLoginWithEmail"
            :disabled="loginButtonEnabled"
          ></Button>
          <Link
            class="recover-password"
            :label="$t('popup.auth.login.recoverPassword')"
            size="small"
            color="secondary"
            @onClick="recoverPassword"
          />
        </div>
      </div>

      <!-- v-if="registerWithEmail" -->
      <div v-if="chooseRegisterType && register" class="register-form--body">
        <div
          class="register-form--header-subtitle-description"
          style="display:none"
        >
          <Label color="primary" size="medium"
            >Registe-se com o seu email...
          </Label>
        </div>
        <div class="register-form--buttons">
          <CpInput
            ref="name"
            :label="$t('placeholder.name') + ' *'"
            :value="myPersonalName"
            :validator="validateName"
          />
        </div>
        <div class="register-form--buttons">
          <CpInput
            ref="email"
            :label="$t('placeholder.email') + ' *'"
            :value="myPersonalEmail"
            :validator="validateEmail"
          />
        </div>
        <div class="register-form--buttons">
          <CpInput
            ref="phone"
            :label="$t('placeholder.phone') + ' *'"
            :value="myPersonalPhone"
            :validator="validatePhone"
          />
        </div>
        <div class="register-form--buttons">
          <CpPassword
            ref="pass"
            @onValidation="([pass]) => (myPersonalPassword = pass)"
          />
        </div>

        <div class="register-form--buttons">
          <PrivacyPolicy
            privacyPolicyLinkURL="/politica-de-privacidade"
            @acceptedPrivacyPolicy="acceptedPrivacyPolicy"
            @acceptedEmailMarketing="acceptedEmailMarketing"
            @onClickPolicyLink="onClickPolicyLinkHandler"
            :textMarketing="$t('privacyPolicy.textMarketing')"
          />
          <Label
            v-if="errorPrivacyPolicy"
            color="tertiary"
            bold="thin"
            size="small"
          >
            {{ errorPrivacyPolicy }}</Label
          >
        </div>
        <div class="register-form--buttons">
          <Button
            class="mb-5"
            type="primary"
            :fill="true"
            :border="false"
            label="Criar Conta"
            :uppercase="true"
            @onClick="clickRegisterWithEmail"
            :disabled="registerButtonEnabled"
          ></Button>
        </div>
      </div>

      <div v-if="loginMyHyundai" class="recover-password--content myhyundai">
        <div class="register-form--buttons">
          <InputForm
            type="primary"
            inputType="text"
            :label="$t('placeholder.email') + ' *'"
            bgColor="#F5F7F9"
            @onChange="
              value => {
                myPersonalEmail = value;
              }
            "
          />
          <Label v-if="errorEmail" color="tertiary" bold="thin" size="small">
            {{ errorEmail }}</Label
          >
        </div>
        <div class="register-form--buttons">
          <InputForm
            type="primary"
            inputType="password"
            :label="$t('placeholder.password') + ' *'"
            bgColor="#F5F7F9"
            @onFocus="onFocusHandler"
            @onChange="
              value => {
                myPersonalPassword = value;
              }
            "
          />
        </div>
        <div class="register-form--buttons">
          <Button
            class="mb-5"
            type="primary"
            :fill="true"
            :border="false"
            :label="$t('popup.auth.login.labelEmail')"
            :uppercase="true"
            @onClick="clickLoginMyHyunday"
            @keyup.enter="clickLoginMyHyunday"
            :disabled="loginButtonEnabled"
          />
          <Link
            class="recover-password"
            :label="$t('popup.auth.myHyundai.recoverPassword.linkLabel')"
            size="small"
            color="secondary"
            :href="myHyundaiRecoverPwdHref"
          />
        </div>
      </div>

      <div class="register-form--header-subtitle">
        <div>
          <Label v-if="register" color="primary" size="small"
            >{{ headerSubtitleRegister }}
          </Label>
          <Label v-if="login" color="primary" size="small"
            >{{ headerSubtitleLogin }}
          </Label>
        </div>
        <div>
          <Link
            v-if="register"
            class="register-form--header-subtitle-link"
            :label="headerLinkRegister"
            size="small"
            color="secondary"
            @onClick="clickLoginRegister"
          />
          <Link
            v-if="login"
            class="register-form--header-subtitle-link"
            :label="headerLinkLogin"
            size="small"
            color="secondary"
            @onClick="clickLoginRegister"
          />
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { Popup, Label, Button, Link } from "../../atoms";
import { InputForm } from "../../molecules";
import { PrivacyPolicy } from "../../organisms";
import { LOGIN_PROVIDERS } from "../../../enums/loginProviders";
import v from "../../../utils/validator";
import CpPassword from "../../refactor/CpPassword.vue";
import CpInput from "../../refactor/CpInput.vue";
import LABEL_TYPE from "../../../utils/labelType";
import formValidator from "../../../utils/formValidator";

export default {
  components: {
    Label,
    Popup,
    Button,
    Link,
    InputForm,
    PrivacyPolicy,
    CpPassword,
    CpInput
  },
  data() {
    return {
      myForm: {},
      opened: false,
      register: !this.isLogin,
      login: this.isLogin,
      loginMyHyundai: false,
      recoverPasswordPopup: false,
      chooseRegisterType: true,
      registerWithEmail: false,
      registerWithFacebook: false,
      registerWithGoogle: false,
      errorEmail: "",
      errorName: "",
      errorPhone: "",
      errorPassword: "",
      errorPrivacyPolicy: "",
      myPersonalEmail: "",
      myPersonalName: "",
      myPersonalPhone: "",
      myPersonalPassword: "",
      myPrivacyPolicy: false,
      myEmailMarketing: false,
      showInfoMessage: false
    };
  },
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    passwordSent: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validatePhone(e) {
      this.myPersonalPhone = e;
      const re = /^(\+?351|(00)?351)?\s?9[2,6,3,1]\d\s?\d{3}\s?\d{3}$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.phone")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateEmail(e) {
      this.myPersonalEmail = e;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.email")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateName(e) {
      this.myPersonalName = e;
      if (!e) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.name")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },

    closeModal() {
      this.opened = false;
      this.chooseRegisterType = true;
      this.registerWithEmail = false;
      this.$emit("close");
    },
    openModal() {
      this.opened = true;
    },
    clickChooseEmail() {
      this.chooseRegisterType = false;
      this.registerWithEmail = true;
    },
    facebookLogin() {
      this.$emit("onFacebookLogin");
    },
    googleLogin() {
      this.$emit("onGoogleLogin");
    },
    myHyundayLogin() {
      this.chooseRegisterType = false;
      this.login = false;
      this.loginMyHyundai = true;
    },
    clickRecoverPassword() {
      this.errorEmail = v("emailOnly", { email: this.myPersonalEmail }).email;

      if (!this.errorEmail) {
        this.$emit("onRecoverPassword", this.myPersonalEmail);
        this.recoverPasswordPopup = false;
      }
    },
    clickRegisterWithEmail() {
      const refs = [
        {
          ref: "name",
          value: this.myPersonalName
        },
        {
          ref: "email",
          value: this.myPersonalEmail
        },
        {
          ref: "phone",
          value: this.myPersonalPhone
        },
        {
          ref: "pass",
          value: this.myPersonalPassword
        }
      ];

      const errs = formValidator(refs, this.$refs);
      if (errs.length > 0 || !this.myPrivacyPolicy) {
        return false;
      }

      const user = {
        email: this.myPersonalEmail,
        name: this.myPersonalName,
        password: this.myPersonalPassword,
        phoneNumber: this.myPersonalPhone,
        privacyPolicy: this.myPrivacyPolicy,
        emailMarketing: this.myEmailMarketing,
        providerId: LOGIN_PROVIDERS.EMAIL
      };
      this.$store.commit("spinnerModule/setLoading", true, { root: true });
      this.$emit("onRegister", user);
    },
    clickLoginWithEmail() {
      if (!this.myPersonalEmail) {
        this.errorEmail = this.$t("validators.requiredEmail");
      } else if (!this.validEmail(this.myPersonalEmail)) {
        this.errorEmail = this.$t("validators.validEmail");
      } else {
        this.errorEmail = null;
      }

      if (this.errorEmail === null) {
        const user = {
          email: this.myPersonalEmail,
          password: this.myPersonalPassword,
          providerId: LOGIN_PROVIDERS.EMAIL
        };

        this.$emit("onLogin", user);
      }
    },
    clickLoginMyHyunday() {
      if (!this.myPersonalEmail) {
        this.errorEmail = this.$t("validators.requiredEmail");
      } else if (!this.validEmail(this.myPersonalEmail)) {
        this.errorEmail = this.$t("validators.validEmail");
      } else {
        this.errorEmail = null;
      }
      if (!this.myPersonalPassword) {
        this.errorPassword = this.$t("validators.requiredPassword");
      } else {
        this.errorPassword = null;
      }

      if (this.errorEmail === null && this.errorPassword === null) {
        const user = {
          email: this.myPersonalEmail,
          password: this.myPersonalPassword,
          providerId: LOGIN_PROVIDERS.MYHYUNDAI
        };

        this.$emit("onLoginMyHyundai", user);
      }
    },
    validEmail: function(email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPassword: function(password) {
      let re = /^(?=(.*[a-z].*){1,})(?=(.*[A-Z].*){1,})(?=.*\d.*)(?=.*[- *_$!?.:;,].*)[a-zA-Z0-9\S]{8,14}$/;
      return re.test(password);
    },
    acceptedPrivacyPolicy(event) {
      this.myPrivacyPolicy = event;
    },
    acceptedEmailMarketing(event) {
      this.myEmailMarketing = event;
    },
    clickLoginRegister() {
      this.clearVariables();
      if (this.register === true) {
        this.register = false;
        this.login = true;
      } else {
        this.register = true;
        this.login = false;
      }
      this.registerWithEmail = false;
      this.chooseRegisterType = true;
    },
    clickLogin() {
      this.clearVariables();
      this.recoverPasswordPopup = false;
      this.register = false;
      this.registerWithEmail = false;
      this.chooseRegisterType = true;
      this.login = true;
    },
    recoverPassword() {
      this.clearVariables();
      this.register = false;
      this.login = false;
      this.loginMyHyundai = false;
      this.recoverPasswordPopup = true;
    },
    clearVariables() {
      this.errorEmail = "";
      this.errorName = "";
      this.errorPassword = "";
      this.errorPrivacyPolicy = "";
      this.myPersonalEmail = "";
      this.myPersonalName = "";
      this.myPersonalPassword = "";
    },
    onClickPolicyLinkHandler() {
      window.open("/politica-de-privacidade", "_blank");
    },
    onFocusHandler() {
      this.errorPasswordHandler();
      this.showInfoMessage = true;
    },
    errorPasswordHandler() {
      this.errorPassword = this.$t("validators.validPassword");
    }
  },
  computed: {
    loginButtonEnabled() {
      return !this.myPersonalEmail || !this.myPersonalPassword;
    },
    registerButtonEnabled() {
      return (
        !this.myPersonalName ||
        !this.myPersonalEmail ||
        !this.myPersonalPhone ||
        !this.myPersonalPassword ||
        !this.myPrivacyPolicy
      );
    },
    recoverButtonEnabled() {
      return !this.myPersonalEmail;
    },
    myHyundaiRecoverPwdHref() {
      return this.$t("popup.auth.myHyundai.recoverPassword.href");
    },
    headerSubtitleRegister() {
      return this.$t("popup.auth.register.headerSubtitleRegister");
    },
    headerSubtitleRecoverPassword() {
      return this.$t("popup.auth.recoverPassword.headerSubtitle");
    },
    popupTitlePasswordSent() {
      return this.$t("popup.auth.recoverPassword.passwordSentTitle");
    },
    popupTitleLoginMyHyundai() {
      return this.$t("popup.auth.login.popupTitleLoginMyHyundai");
    },
    popupTitleMyHyundai() {
      return this.$t("popup.auth.login.myHyundai");
    },
    headerLinkRegister() {
      return this.$t("popup.auth.register.headerLinkRegister");
    },
    headerSubtitleLogin() {
      return this.$t("popup.auth.login.headerSubtitleLogin");
    },
    headerLinkLogin() {
      return this.$t("popup.auth.login.headerLinkLogin");
    },
    actionHeaderLink() {
      return "session/register";
    },
    popupTitleLogin() {
      return this.$t("popup.auth.login.popupTitleLogin");
    },
    popupTitleRegister() {
      return this.$t("popup.auth.register.popupTitleRegister");
    },
    popupTitleRecoverPassword() {
      return this.$t("popup.auth.recoverPassword.popupTitleRecoverPassword");
    }
  },
  mounted() {
    this.passwordSent = false;
  }
};
</script>
<style lang="css" scoped src="./RegisterForm.css"></style>
