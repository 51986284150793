import dropdownModule from "./dropdownModule";
import paginationModule from "./paginationModule";
import filterModule from "./filterModule";
import autocompleteModule from "./autocompleteModule";
import i18n from "../../i18n.js";
import { getPriceFormatted } from "../../utils/currency";

import {
  getSimilar,
  search,
  saveSearch,
  getSearches,
  deleteSearch
} from "../../api/search";

const RANDOM_ORDER = "random";
const DESC_ORDER = "desc";
const CREATE_TIME_FILTER = "createTime";

const composedTags = {
  monthlyDeposit: { monthlyPayment: "", depositValue: "" },
  price: { priceInf: "", priceSup: "" },
  km: { kmInf: "", kmSup: "" },
  year: { yearInf: "", yearSup: "" }
};
const composedFilters = {
  kmInf: "km",
  kmSup: "km",
  yearInf: "year",
  yearSup: "year",
  priceInf: "price",
  priceSup: "price",
  monthlyPayment: "monthlyDeposit",
  depositValue: "monthlyDeposit"
};

const currencyTags = ["price", "monthlyDeposit"];

const objFromTags = tags => {
  var filterTags = tags.filter(tag => tag.type !== "needle");
  filterTags = filterTags.map(tag =>
    tag.type === "brands" ? { ...tag, type: "brand" } : tag
  );

  return filterTags.reduce((acc, curr) => {
    if (Object.keys(composedTags).includes(curr.type)) {
      Object.keys(curr.id).forEach(key => (acc[key] = [curr.id[key]]));
    } else if (curr.type.toLowerCase() === "brand") {
      acc["model"] = [...(acc["model"] || []), curr.id];
    } else if (curr.type.toLowerCase() == "dealer") {
      for (const propertyName in curr.id) {
        acc[curr.type] = [...(acc[curr.type] || []), curr.id[propertyName]];
      }
    } else acc[curr.type] = [...(acc[curr.type] || []), curr.id];
    return acc;
  }, {});
};

const state = {
  similar: [],
  recent: [],
  saved: [],
  openSaveSearch: false,
  interest: [],
  latest: [],
  used: []
};

function mapTags(dropdowns, search) {
  var filters = search.searchBody.filters;
  var p;
  var c;
  if (!filters.condition) {
    filters.condition = [1];
  }
  var undefinedTag = false;
  var mappedTags = [
    ...Object.entries(filters)
      .map(f => {
        return f[1].map(id => {
          if (f[0] === "condition") {
            return {
              type: f[0],
              id: id,
              value: i18n.t(`pages.searchpage.searchform.condition.${id}`),
              text: i18n.t(`pages.searchpage.searchform.condition.${id}`)
            };
          } else {
            var value = dropdowns[f[0] === "brand" ? "brands" : f[0]]?.find(
              d => d.key == id
            );
            var parent = composedFilters[f[0]];
            if (parent) {
              composedTags[parent][f[0]] = id;
            } else {
              if (value === undefined) {
                undefinedTag = true;
                return;
              }
              value = value.value;
            }
            const text = parent
              ? Object.values(composedTags[parent]).reduce((acc, val) => {
                  return i18n.t(`pages.searchpage.searchform.${parent}`, {
                    value1: currencyTags.includes(parent)
                      ? getPriceFormatted(acc)
                      : acc,
                    value2: currencyTags.includes(parent)
                      ? getPriceFormatted(val)
                      : val
                  });
                })
              : value;

            return {
              children: c,
              parent: p,
              id: parent
                ? JSON.parse(JSON.stringify(composedTags[parent]))
                : Number(id),
              type: f[0] === "brand" ? "brands" : parent ? parent : f[0],
              value: text,
              text
            };
          }
        });
      })
      .flat()
  ];
  if (undefinedTag) return [];
  if (search.searchBody.needle)
    mappedTags = [
      ...mappedTags,
      {
        id: 0,
        type: "needle",
        value: search.searchBody.needle || ""
      }
    ];
  const simple = mappedTags.filter(
    tag => !Object.keys(composedTags).includes(tag.type)
  );
  const composed = [];
  Object.keys(composedTags).forEach(t => {
    const filtered = mappedTags.filter(tag => tag.type === t);
    if (filtered.length > 0) composed.push(filtered[filtered.length - 1]);
  });
  return composed.concat(simple);
}
function dateToString(milliseconds) {
  const date = new Date(milliseconds);
  const year = new Intl.DateTimeFormat(
    process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
    {
      year: "numeric"
    }
  ).format(date);
  const month = new Intl.DateTimeFormat(
    process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
    {
      month: "long"
    }
  ).format(date);
  const day = new Intl.DateTimeFormat(
    process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
    {
      day: "2-digit"
    }
  ).format(date);
  const week_day = new Intl.DateTimeFormat(
    process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
    {
      weekday: "long"
    }
  ).format(date);

  const formattedDate = week_day + ", " + day + " " + month + " " + year;
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

const getters = {
  getRecent: state => state.recent,
  getUsed: state => state.used,
  getSimilar: state => state.similar,
  getSaved: state => state.saved,
  getInterest: state => state.interest,
  getLatest: state => state.latest,
  getFormattedSearch: state => {
    return state.saved.map(search => ({
      id: search.id,
      title: search.title,
      createTime: dateToString(search.createTime),
      tags: mapTags(state.dropdown.dropdowns, search)
    }));
  },
  isOpenSaveSearch: state => state.openSaveSearch,
  getFormattedFilters: state => {
    return objFromTags(state.filter.tags);
  }
};

const mutations = {
  setRecent(state, data) {
    let newData = data;
    if (data.length)
      newData = data.map(el => {
        const newEl = el;
        newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
        newEl.previousPvp =
          el.prices?.filter(p => p.priceType === "TotalPrice") &&
          el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            ? el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            : 0;
        return newEl;
      });
    state.recent = newData;
  },
  setSimilar(state, data) {
    let newData = data;
    if (data.length)
      newData = data.map(el => {
        const newEl = el;
        newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
        newEl.previousPvp =
          el.prices?.filter(p => p.priceType === "TotalPrice") &&
          el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            ? el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            : 0;
        return newEl;
      });
    state.similar = newData;
  },
  setSaved(state, data) {
    let newData = data;
    if (data.length)
      newData = data.map(el => {
        const newEl = el;
        newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
        newEl.previousPvp =
          el.prices?.filter(p => p.priceType === "TotalPrice") &&
          el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            ? el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            : 0;
        return newEl;
      });
    state.saved = newData;
  },
  setOpenSaveSearch(state) {
    state.openSaveSearch = !state.openSaveSearch;
  },
  setInterest(state, data) {
    let newData = data;
    if (data?.length)
      newData = data.map(el => {
        const newEl = el;
        newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
        newEl.previousPvp =
          el.prices?.filter(p => p.priceType === "TotalPrice") &&
          el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            ? el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            : 0;
        return newEl;
      });
    state.interest = newData;
  },
  setLatest(state, data) {
    let newData = data;
    if (data?.length)
      newData = data.map(el => {
        const newEl = el;
        newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
        newEl.previousPvp =
          el.prices?.filter(p => p.priceType === "TotalPrice") &&
          el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            ? el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            : 0;
        return newEl;
      });
    state.latest = newData;
  },
  setUsed(state, data) {
    let newData = data;
    if (data?.length)
      newData = data.map(el => {
        const newEl = el;
        newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
        newEl.previousPvp =
          el.prices?.filter(p => p.priceType === "TotalPrice") &&
          el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            ? el.prices?.filter(p => p.priceType === "TotalPrice").previousValue
            : 0;
        return newEl;
      });
    state.used = newData;
  }
};

const actions = {
  async fetchSimilar({ commit }) {
    try {
      const res = await getSimilar();
      commit("setSimilar", res.data.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchRecent({ commit, state }, { quickSearchItem, dynamic = false }) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const page = state.pagination.current;
      const payload = {
        filters:
          quickSearchItem === undefined || quickSearchItem.length === 0
            ? objFromTags(state.filter.tags)
            : (commit("filter/setTags", quickSearchItem),
              objFromTags(quickSearchItem)),
        needle: state.filter.needle
      };
      payload.filters.condition = [1];
      const {
        data: {
          data: { searchResult, searchUrl },
          pagination
        }
      } = await search(payload, page, state.filter.sort, state.filter.order);
      commit("setRecent", searchResult);
      if (!dynamic) history.pushState({}, null, searchUrl); ///TODO: USE PATH FROM SEARCH RESPONSE
      commit("pagination/setPagination", pagination);
    } catch (err) {
      console.error(err);
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async fetchUsed({ commit, state }, { quickSearchItem, dynamic = false }) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const page = state.pagination.current;
      const payload = {
        filters:
          quickSearchItem === undefined || quickSearchItem.length === 0
            ? objFromTags(state.filter.tags)
            : (commit("filter/setTags", quickSearchItem),
              objFromTags(quickSearchItem)),
        needle: state.filter.needle
      };

      payload.filters.condition = [2];
      const {
        data: {
          data: { searchResult, searchUrl },
          pagination
        }
      } = await search(payload, page, state.filter.sort, state.filter.order);
      commit("setUsed", searchResult);
      if (!dynamic) history.pushState({}, null, searchUrl); ///TODO: USE PATH FROM SEARCH RESPONSE
      commit("setRecent", searchResult);
      commit("pagination/setPagination", pagination);
    } catch (err) {
      console.error(err);
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  requestSaveSearch({ commit, state }, { title, used }) {
    commit("spinnerModule/setLoading", true, { root: true });
    var tags = objFromTags(
      state.filter.tags.filter(tag => tag.type !== "needle")
    );
    if (used) tags.condition = [2];
    else tags.condition = [1];
    try {
      saveSearch({
        title: title,
        searchBody: {
          filters: tags,
          needle: state.filter.needle
        }
      });
    } catch (e) {
      return e;
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async getSearches({ commit }) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const {
        data: { data }
      } = await getSearches();
      commit("setSaved", data);
    } catch (e) {
      return e;
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async deleteSearch({ commit, dispatch }, id) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      await deleteSearch(id);
      dispatch("getSearches");
    } catch (e) {
      return e;
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async fetchInterest({ commit, state }) {
    try {
      const page = state.pagination.current;
      const payload = {
        filters: {},
        needle: ""
      };

      const {
        data: {
          data: { searchResult }
        }
      } = await search(payload, page, state.filter.sort, RANDOM_ORDER, 4, 0);
      commit("setInterest", searchResult);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchLatest({ commit, state }) {
    try {
      const page = state.pagination.current;
      const payload = {
        filters: {},
        needle: ""
      };

      const {
        data: {
          data: { searchResult }
        }
      } = await search(payload, page, CREATE_TIME_FILTER, DESC_ORDER, 4, 0);
      commit("setLatest", searchResult);
    } catch (err) {
      console.error(err);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    dropdown: dropdownModule,
    filter: filterModule,
    pagination: paginationModule,
    autocomplete: autocompleteModule
  }
};
