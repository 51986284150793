<template>
  <div class="wrapper">
    <div
      :class="['container', customClass, disabled ? 'disabled' : '', validated]"
    >
      <input
        @focus="onFocus()"
        @blur="onBlur()"
        :value="value"
        :type="inputType"
        @input="handleInput"
        required
        :disabled="disabled"
      />
      <label :class="{ focused: value }">{{ label }}</label>
      <CpIcon v-if="icon" class="input--icon" :icon="icon" />
    </div>
    <div class="flex" v-if="showLabel">
      <div v-if="messageIcon" class="label--icon" :class="labelIcon" />
      <span
        v-if="validated"
        :class="[messageClass, messageIcon ? 'message-icon' : 'message']"
      >
        {{ message }}
      </span>
    </div>
  </div>
</template>

<script>
import CpIcon from "./CpIcon.vue";
import LABEL_TYPE from "../../utils/labelType";
import { keyByVal } from "../../utils/funcs";

export default {
  name: "CpInput",
  components: {
    CpIcon
  },
  props: {
    customClass: {
      type: String
    },
    value: {
      type: [String, Number, Date],
      default: ""
    },
    label: {
      type: String,
      default: "TextField"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    validator: {
      type: Function,
      default: () => {}
    },
    messageIcon: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: "text",
      validator: t => ["text", "date", "password", "email"].includes(t)
    }
  },
  data() {
    return {
      showLabel: false,
      validated: "",
      message: ""
    };
  },
  methods: {
    onFocus() {
      this.showLabel = true;
    },
    onBlur() {
      this.showLabel = true;
    },
    matcher(entry, suffix) {
      if (!entry) return "";
      const [type] = entry.split("-");
      return `${type}-${suffix}`;
    },
    handleInput(e) {
      const out = this.validator(e.target.value);
      this.$emit("onChange", e.target.value);
      if (out) {
        const [res, msg] = out;
        this.message = msg;
        if (res) {
          this.validated = `${keyByVal(LABEL_TYPE, res).toLowerCase()}-label`;
        }
        return res === LABEL_TYPE.SUCCESS;
      }
    }
  },
  computed: {
    messageClass() {
      return this.matcher(this.validated, "message");
    },
    labelIcon() {
      return this.matcher(this.validated, "icon");
    }
  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: auto;
  background-color: #f5f7f9;
}

input,
label {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

input,
textarea {
  background-color: transparent;
}

input {
  height: 56px;
  padding-left: 16px;
  padding-top: 28px;
  padding-bottom: 12px;
  outline: none;
  color: #02226f;
  width: 100%;
}

label {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 20px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  color: #757989;
}

input:focus ~ label,
input:valid ~ label,
.focused {
  top: 10px;
  font-size: 14px;
  line-height: 14px;
}

.container:hover,
.container:focus-within {
  box-shadow: inset 0px -2px 0px #02226f;
}

.input--icon {
  margin-right: 4px;
}

.error-label,
.error-label:hover,
.error-label:focus-within {
  box-shadow: inset 0px -2px 0px #e63312;
}

.warning-label,
.warning-label:hover,
.warning-label:focus-within {
  box-shadow: inset 0px -2px 0px #ffa300;
}
.info-label,
.info-label:hover,
.info-label:focus-within {
  box-shadow: inset 0px -2px 0px #00aad2;
}
E6 .success-label,
.success-label:hover,
.success-label:focus-within {
  box-shadow: inset 0px -2px 0px #43a047;
}

.message,
.message-icon {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  padding-top: 7px;
}

.message-icon {
  padding-left: 4px;
}

.message {
  padding-left: 16px;
}

.success-message {
  color: #43a047;
}

.error-message {
  color: #e63312;
}
.info-message {
  color: #00aad2;
}
.warning-message {
  color: #ffa300;
}

.label--icon {
  background-size: contain;
  background-position: center;
  height: 18px;
  width: 18px;
  margin-top: 4px;
}

.error-icon {
  background-image: url("../../assets/images/exclamationMarkError.svg");
}
.warning-icon {
  background-image: url("../../assets/images/exclamationMarkWarning.svg");
}
.success-icon {
  background-image: url("../../assets/images/successSuccess.svg");
}
.info-icon {
  background-image: url("../../assets/images/infoInfo.svg");
}
</style>
