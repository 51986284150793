import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { getTranslation } from "./api/translations";
import { LOCALES } from "./enums/locales";

import "./fonts/Hyundai/stylesheet.css";

import "./css/main.css";

import i18n from "./i18n";

import VueSocialSharing from "vue-social-sharing";

import VueMeta from "vue-meta";

Vue.use(VueSocialSharing);

window.EventBus = new Vue();

/* FontAwesome Component */
// Don't forget to add all icons in .storybook/preview.js
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookSquare,
  faTwitter,
  faWhatsapp,
  faFacebookF,
  faGoogle
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faHeart, faCheckCircle } from "@fortawesome/free-regular-svg-icons";

import {
  faBalanceScale,
  faSearch,
  faLock,
  faUserSecret,
  faBell,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faTimes,
  faClock,
  faShoppingCart,
  faEuroSign,
  faChevronDown,
  faChevronUp,
  faShareSquare,
  faTruck,
  faHandHoldingUsd,
  faCar,
  faQuestionCircle,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faBars,
  faArrowDown,
  faCheck,
  faUserCircle,
  faExchangeAlt,
  faEye,
  faHeart as faHeartSolid,
  faPhoneAlt,
  faShareAlt,
  faEnvelopeSquare,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(faSearch);
library.add(faLock);
library.add(faUserSecret);
library.add(faBell);
library.add(faInfoCircle);
library.add(faCheckCircle);
library.add(faExclamationTriangle);
library.add(faExclamationCircle);
library.add(faTimes);
library.add(faClock);
library.add(faShoppingCart);
library.add(faEuroSign);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faShareSquare);
library.add(faTruck);
library.add(faHandHoldingUsd);
library.add(faCar);
library.add(faQuestionCircle);
library.add(faCaretDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faFacebookSquare);
library.add(faBars);
library.add(faArrowDown);
library.add(faHeart);
library.add(faCheck);
library.add(faHeartSolid);
library.add(faUserCircle);
library.add(faExchangeAlt);
library.add(faCalendarAlt);
library.add(faEye);
library.add(faPhoneAlt);
library.add(faShareAlt);
library.add(faGoogle);
library.add(faEnvelopeSquare);
library.add(faBalanceScale);

library.add(faFacebookF);
library.add(faTwitter);
library.add(faWhatsapp);

Vue.component("font-awesome-icon", FontAwesomeIcon);
/* End FontAwesome */

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

Vue.use(VueToast, {
  // One of the options
  position: "top-right"
});

import VueCountryCode from "vue-country-code-select";
Vue.use(VueCountryCode);

Vue.config.productionTip = false;

Vue.use(VueMeta);

const locale = process.env.VUE_APP_I18N_LOCALE;

getTranslation(locale)
  .then(res => {
    const data = JSON.parse(res.data.data[0].translations);
    i18n.setLocaleMessage(LOCALES[locale], data);
  })
  .catch(err => {
    console.error("Error - ", err);
  });
  
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
