<template>
  <Block class="search-form">
    <div class="search-form--row1">
      <Label size="small" bold="bold" :uppercase="true" color="primary">{{
        $t("pages.searchpage.searchform.filters")
      }}</Label>
    </div>
    <div :class="cssClassSearchForm">
      <div class="search-form--row2-input">
        <AutocompleteDropdown
          class="search-form--row2-input-search"
          type="tertiary"
          :results="getAllResults"
          :loading="getAutoCompleteLoadingState"
          :placeholder="
            $t('pages.searchpage.searchform.autocompleteDropdownPlaceholder')
          "
          @onSearch="onStoreResults"
          @onSelect="handleSearchButton"
          @onChange="
            value => {
              mySearch = value;
            }
          "
          ref="autocompleteDropdown"
        />

        <Button
          class="search-button"
          type="primary"
          :uppercase="true"
          size="small"
          :fill="true"
          icon="search"
          @onClick="handleSearchButton"
        />
      </div>
      <div class="search-form--row2-filters">
        <Dropdown
          :id="generateId('price-dropdown')"
          customClass="search-form--huge-dropdown"
          @onOpen="onPriceDropdownOpened"
          :uppercase="true"
          :title="
            $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.title')
          "
          bgColor="white"
          @close="closeModal"
        >
          <PriceSearchDetails
            v-if="renderComponent"
            customClass="price-search-details"
            :topRangeStep="Number(financeProps?.monthlyStep)"
            :topRangeValue="Math.floor(currentMonthlyValue * 0.1) * 10"
            :topRangeMin="multiples(financeProps.monthlyMin)"
            :topRangeMax="multiples(financeProps.monthlyMax)"
            :bottomRangeValue="currentDeposit"
            :leftButtonLabel="
              $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.monthly')
            "
            :rightButtonLabel="
              $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.price')
            "
            :topRangeLabel="
              $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.monthly')
            "
            :bottomRangeLabel="
              $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.entry')
            "
            :bottomRangeMin="getDepositMin"
            :bottomRangeMax="getDepositMax"
            :bottomRangeStep="Number(financeProps.depositStep)"
            leftInputPlaceholder="0"
            rightInputPlaceholder="0"
            :resumeObj="resumeObj"
            :checkedToggle="getIncludeRetake"
            @onChangeRange="onChangeRangeHandler"
            @onChangeInput="onChangeInputHandler($event, 'price')"
            @onToggle="onToggleHandler"
          />
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          customClass="search-form--huge-dropdown"
          :id="generateId('segment-dropdown')"
          :uppercase="true"
          :title="$t('pages.searchpage.searchform.dropdownSegment.title')"
          bgColor="white"
          @close="closeModal"
        >
          <div class="search-form--row2-filters-segmento">
            <div
              class="search-form--row2-filters-segmento-item"
              v-for="(item, index) in segmentList"
              :key="index + renderKey"
            >
              <div @click="createTag(item, 'segment')">
                <div
                  :class="[
                    'search-form--row2-filters-segmento-image-segment',
                    segmentImage(item)
                  ]"
                ></div>

                <span v-if="!item.selected">{{ item.value }}</span>
                <span v-if="item.selected" class="selected"
                  >{{ item.value }}
                  <Icon
                    class="check"
                    color="secondary"
                    name="check"
                    size="x-small"
                    iconPrefix="fa"
                  />
                </span>
              </div>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          customClass="search-form--huge-dropdown"
          :uppercase="true"
          :title="$t('pages.searchpage.searchform.dropdownModel.title')"
          bgColor="white"
          @close="closeModal"
          :id="generateId('model-dropdown')"
        >
          <div
            class="
              search-form--row2-filters-segmento search-form--model-dropdown
            "
          >
            <div
              class="search-form--row2-filters-segmento-item"
              v-for="(item, index) in filterModelList"
              :key="index + renderKey"
            >
              <div @click="createTag(item, 'model')">
                <img
                  class="search-form--row2-filters-segmento-image"
                  :src="item.imageURL"
                />

                <span v-if="!item.selected">{{ item.value }}</span>
                <span v-if="item.selected" class="selected"
                  >{{ item.value }}
                  <Icon
                    class="check"
                    color="secondary"
                    name="check"
                    size="x-small"
                    iconPrefix="fa"
                  />
                </span>
              </div>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          customClass="search-form--huge-dropdown"
          :uppercase="true"
          :title="$t('pages.searchpage.searchform.dropdownKilometers.title')"
          bgColor="white"
          @close="closeModal"
          id="kilometers-dropdown"
          v-if="usedCars"
        >
          <div class="search-form--row2-filters-kilometers-wrapper">
            <div class="search-form--row2-filters-kilometers">
              <span class="search-form--row2-filters-kilometers-text">{{
                $t("pages.searchpage.searchform.dropdownKilometers.from")
              }}</span>
              <div
                class="search-form--row2-filters-kilometers-item"
                v-for="(item, index) in kmInfList"
                :key="index + renderKey"
                @click="handleKmDropdown(item, 'from')"
              >
                <div
                  class="search-form--row2-filters-kilometers-checkbox-wrapper"
                >
                  <div class="search-form--row2-filters-kilometers-checkbox">
                    <Icon
                      class="check-filters"
                      color="secondary"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      v-if="item.selected"
                    />
                  </div>
                </div>
                <span class="search-form--row2-filters-kilometers-text">{{
                  item.value
                }}</span>
              </div>
            </div>
            <div class="search-form--row2-filters-kilometers">
              <span class="search-form--row2-filters-kilometers-text">{{
                $t("pages.searchpage.searchform.dropdownKilometers.to")
              }}</span>
              <div
                class="search-form--row2-filters-kilometers-item"
                v-for="(item, index) in kmSupList"
                :key="index + renderKey"
                @click="handleKmDropdown(item, 'to')"
              >
                <div
                  class="search-form--row2-filters-kilometers-checkbox-wrapper"
                >
                  <div class="search-form--row2-filters-kilometers-checkbox">
                    <Icon
                      class="check-filters"
                      color="secondary"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      v-if="item.selected"
                    />
                  </div>
                </div>
                <span class="search-form--row2-filters-kilometers-text">{{
                  item.value
                }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="search-form--row2-filters-kilometers">
            <div
              class="search-form--row2-filters-kilometers-item"
              v-for="(item, index) in kmInfList"
              :key="index + renderKey"
            >
              <div @click="createTag(item, 'km')">
                <div class="search-form--row2-filters-kilometers-checkbox">
                  <Icon
                    class="check"
                    color="secondary"
                    name="check"
                    size="x-small"
                    iconPrefix="fa"
                    v-if="item.selected"
                  />
                </div>
                <span v-if="!item.selected">{{ item.value }}</span>
              </div>
            </div>
          </div> -->
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          customClass="search-form--huge-dropdown"
          :uppercase="true"
          :title="$t('pages.searchpage.searchform.dropdownYears.title')"
          bgColor="white"
          @close="closeModal"
          id="year-dropdown"
          v-if="usedCars"
        >
          <div class="search-form--row2-filters-kilometers-wrapper">
            <div class="search-form--row2-filters-kilometers">
              <span class="search-form--row2-filters-kilometers-text">{{
                $t("pages.searchpage.searchform.dropdownYears.from")
              }}</span>
              <div
                class="search-form--row2-filters-kilometers-item"
                v-for="(item, index) in yearInfList"
                :key="index + renderKey"
                @click="handleYearDropdown(item, 'from')"
              >
                <div
                  class="search-form--row2-filters-kilometers-checkbox-wrapper"
                >
                  <div class="search-form--row2-filters-kilometers-checkbox">
                    <Icon
                      class="check-filters"
                      color="secondary"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      v-if="item.selected"
                    />
                  </div>
                </div>
                <span class="search-form--row2-filters-kilometers-text">{{
                  item.value
                }}</span>
              </div>
            </div>
            <div class="search-form--row2-filters-kilometers">
              <span class="search-form--row2-filters-kilometers-text">{{
                $t("pages.searchpage.searchform.dropdownYears.to")
              }}</span>
              <div
                class="search-form--row2-filters-kilometers-item"
                v-for="(item, index) in yearSupList"
                :key="index + renderKey"
                @click="handleYearDropdown(item, 'to')"
              >
                <div
                  class="search-form--row2-filters-kilometers-checkbox-wrapper"
                >
                  <div class="search-form--row2-filters-kilometers-checkbox">
                    <Icon
                      class="check-filters"
                      color="secondary"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      v-if="item.selected"
                    />
                  </div>
                </div>
                <span class="search-form--row2-filters-kilometers-text">{{
                  item.value
                }}</span>
              </div>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          :uppercase="true"
          bgColor="white"
          :title="$t('pages.searchpage.searchform.dropdownFuel.title')"
          @close="closeModal"
          :id="generateId('fuel-dropdown')"
        >
          <div
            class="menu--item"
            v-for="(item, index) in fuelList"
            :key="index + renderKey"
          >
            <!-- replace with routerlink -->

            <div @click="createTag(item, 'fuel')">
              <a v-if="!item.selected">
                {{ item.value }}
              </a>
              <a v-if="item.selected" class="selected">
                {{ item.value }}
                <Icon
                  class="check"
                  name="check"
                  size="x-small"
                  iconPrefix="fa"
                  color="secondary"
                />
              </a>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          :uppercase="true"
          bgColor="white"
          :title="$t('pages.searchpage.searchform.dropdownTransmission.title')"
          @close="closeModal"
          :id="generateId('filters-dropdown')"
        >
          <div
            class="menu--item"
            v-for="(item, index) in transmissionList"
            :key="index + renderKey"
          >
            <!-- replace with routerlink -->
            <div @click="createTag(item, 'transmission')">
              <a v-if="!item.selected">
                {{ item.value }}
              </a>
              <a v-if="item.selected" class="selected">
                {{ item.value }}
                <Icon
                  class="check"
                  name="check"
                  size="x-small"
                  iconPrefix="fa"
                  color="secondary"
                />
              </a>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          class="dropdown-ml"
          customClass="search-form--huge-dropdown"
          :uppercase="true"
          :title="$t('pages.searchpage.searchform.dropdownLocation.title')"
          bgColor="white"
          @close="closeModal"
          id="location-dropdown"
          v-if="usedCars"
        >
          <div
            class="menu--item"
            v-for="(item, index) in locationList"
            :key="index + renderKey"
          >
            <!-- replace with routerlink -->

            <div @click="handleLocation(index)">
              <a v-if="!item.selected">
                {{ item.value }}
              </a>
              <a v-if="item.selected" class="selected">
                {{ item.value }}
                <Icon
                  class="check"
                  name="check"
                  size="x-small"
                  iconPrefix="fa"
                  color="secondary"
                />
              </a>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
    <div class="search-form--row3" v-if="cleanTags && cleanTags.length > 0">
      <div class="search-form--row3-left">
        <div v-for="(tag, index) in cleanTags" :key="index + renderKey">
          <Tag
            customClass="tag"
            type="primary"
            :uppercase="true"
            :label="tag.text"
            @onClick="handleRemoveTag(tag)"
          ></Tag>
        </div>
        <div class="clear-tags">
          <Icon class="check" color="secondary" name="bell" size="x-small" />
          <span class="save" @click="openSaveSearchModal">{{
            $t("pages.searchpage.searchform.linkSaveSearch")
          }}</span>
        </div>
      </div>

      <div class="clear-tags">
        <span class="save" @click="handleCleanSearch">{{
          $t("pages.searchpage.searchform.cleanFilters")
        }}</span>
      </div>
    </div>

    <RightSidebar
      @onClose="closeSaveSearchModal"
      v-if="saveSearchModalOpened"
      title="Guardar Pesquisa"
      description="Guarde as suas pesquisas favoritas para retomar mais tarde."
    >
      <template v-slot:content>
        <div class="right-sidebar-input">
          <Label :uppercase="true" color="primary" bold="bold" size="small">
            Adicione uma descrição a esta pesquisa
          </Label>
          <Input
            inputType="text"
            name="titleSearch"
            type="tertiary"
            :border="true"
            v-model="myTitleSaveSearch"
            @onChange="
              value => {
                myTitleSaveSearch = value;
              }
            "
          />
        </div>
        <div class="right-sidebar-button">
          <Button
            @onClick="saveSearch"
            type="primary"
            uppercase
            label="guardar"
            size="x-large"
            :fill="true"
            :disabled="myTitleSaveSearch.length < 1"
          ></Button>
        </div>
      </template>
    </RightSidebar>

    <Panel
      class="filters-model"
      :open="isFilterPanelOpened"
      @onClose="closeFilterSearchModal"
    >
      <template slot="header">
        <Label size="medium" color="primary">Filtros</Label>
      </template>
      <template slot="content">
        <div
          class="search-form--row-filters"
          v-if="cleanTags && cleanTags.length > 0"
        >
          <div class="search-form--row-filters-left">
            <div v-for="(tag, index) in cleanTags" :key="index + renderKey">
              <Tag
                customClass="tag"
                type="primary"
                :uppercase="true"
                :label="tag.text"
                @onClick="handleRemoveTag(tag)"
              >
              </Tag>
            </div>
            <div class="clear-tags">
              <Icon
                class="check"
                color="secondary"
                name="bell"
                size="x-small"
              />
              <span class="save" @click="openSaveSearchModal">{{
                $t("pages.searchpage.searchform.linkSaveSearch")
              }}</span>
            </div>
          </div>

          <div class="clear-tags">
            <span class="save" @click="handleCleanSearch">{{
              $t("pages.searchpage.searchform.cleanFilters")
            }}</span>
          </div>
        </div>
        <div class="search-form--row2">
          <div class="search-form--row2-filters-modal">
            <Dropdown
              id="price-dropdown-modal"
              customClass="search-form--huge-dropdown"
              @onOpen="onPriceDropdownOpened"
              :uppercase="true"
              :title="
                $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.title')
              "
              bgColor="white"
              @close="closeModal"
            >
              <PriceSearchDetails
                v-if="renderComponent"
                customClass="price-search-details"
                :topRangeStep="getMonthlyStep"
                :topRangeValue="Math.floor(currentMonthlyValue * 0.1) * 10"
                :topRangeMin="multiples(financeProps.monthlyMin)"
                :topRangeMax="multiples(financeProps.monthlyMax)"
                :bottomRangeValue="currentDeposit"
                :leftButtonLabel="
                  $t(
                    'pages.searchpage.searchform.dropdownMonthlyAndPrice.monthly'
                  )
                "
                :rightButtonLabel="
                  $t(
                    'pages.searchpage.searchform.dropdownMonthlyAndPrice.price'
                  )
                "
                :topRangeLabel="
                  $t(
                    'pages.searchpage.searchform.dropdownMonthlyAndPrice.monthly'
                  )
                "
                :bottomRangeLabel="
                  $t(
                    'pages.searchpage.searchform.dropdownMonthlyAndPrice.entry'
                  )
                "
                :bottomRangeMin="getDepositMin"
                :bottomRangeMax="getDepositMax"
                :bottomRangeStep="Number(financeProps.depositStep)"
                :leftInputPlaceholder="
                  Math.floor(financeProps.priceMin).toString()
                "
                :rightInputPlaceholder="
                  Math.ceil(financeProps.priceMax).toString()
                "
                :resumeObj="resumeObj"
                :checkedToggle="getIncludeRetake"
                @onChangeRange="onChangeRangeHandler"
                @onChangeInput="onChangeInputHandler($event, 'price')"
                @onToggle="onToggleHandler"
              />
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              customClass="search-form--huge-dropdown"
              id="segment-dropdown-modal"
              :uppercase="true"
              :title="$t('pages.searchpage.searchform.dropdownSegment.title')"
              bgColor="white"
              @close="closeModal"
            >
              <div class="search-form--row2-filters-segmento">
                <div
                  class="search-form--row2-filters-segmento-item"
                  v-for="(item, index) in segmentList"
                  :key="index + renderKey"
                >
                  <div @click="createTag(item, 'segment')">
                    <div
                      :class="[
                        'search-form--row2-filters-segmento-image-segment',
                        segmentImage(item)
                      ]"
                    ></div>

                    <span v-if="!item.selected">{{ item.value }}</span>
                    <span v-if="item.selected" class="selected"
                      >{{ item.value }}
                      <Icon
                        class="check"
                        color="secondary"
                        name="check"
                        size="x-small"
                        iconPrefix="fa"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              customClass="search-form--huge-dropdown"
              :uppercase="true"
              :title="$t('pages.searchpage.searchform.dropdownModel.title')"
              bgColor="white"
              @close="closeModal"
              id="model-dropdown-modal"
            >
              <div
                class="
                search-form--row2-filters-segmento search-form--model-dropdown
              "
              >
                <div
                  class="search-form--row2-filters-segmento-item"
                  v-for="(item, index) in filterModelList"
                  :key="index + renderKey"
                >
                  <div @click="createTag(item, 'model')">
                    <img
                      class="search-form--row2-filters-segmento-image"
                      :src="item.imageURL"
                    />

                    <span v-if="!item.selected">{{ item.value }}</span>
                    <span v-if="item.selected" class="selected"
                      >{{ item.value }}
                      <Icon
                        class="check"
                        color="secondary"
                        name="check"
                        size="x-small"
                        iconPrefix="fa"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              customClass="search-form--huge-dropdown"
              :uppercase="true"
              :title="
                $t('pages.searchpage.searchform.dropdownKilometers.title')
              "
              bgColor="white"
              @close="closeModal"
              id="kilometers-dropdown-panel"
              v-if="usedCars"
            >
              <div class="search-form--row2-filters-kilometers-wrapper">
                <div class="search-form--row2-filters-kilometers">
                  <span class="search-form--row2-filters-kilometers-text">{{
                    $t("pages.searchpage.searchform.dropdownKilometers.from")
                  }}</span>
                  <div
                    class="search-form--row2-filters-kilometers-item"
                    v-for="(item, index) in kmInfList"
                    :key="index + renderKey"
                    @click="handleKmDropdown(item, 'from')"
                  >
                    <div
                      class="search-form--row2-filters-kilometers-checkbox-wrapper"
                    >
                      <div
                        class="search-form--row2-filters-kilometers-checkbox"
                      >
                        <Icon
                          class="check-filters"
                          color="secondary"
                          name="check"
                          size="x-small"
                          iconPrefix="fa"
                          v-if="item.selected"
                        />
                      </div>
                    </div>
                    <span class="search-form--row2-filters-kilometers-text">{{
                      item.value
                    }}</span>
                  </div>
                </div>
                <div class="search-form--row2-filters-kilometers">
                  <span class="search-form--row2-filters-kilometers-text">{{
                    $t("pages.searchpage.searchform.dropdownKilometers.to")
                  }}</span>
                  <div
                    class="search-form--row2-filters-kilometers-item"
                    v-for="(item, index) in kmSupList"
                    :key="index + renderKey"
                    @click="handleKmDropdown(item, 'to')"
                  >
                    <div
                      class="search-form--row2-filters-kilometers-checkbox-wrapper"
                    >
                      <div
                        class="search-form--row2-filters-kilometers-checkbox"
                      >
                        <Icon
                          class="check-filters"
                          color="secondary"
                          name="check"
                          size="x-small"
                          iconPrefix="fa"
                          v-if="item.selected"
                        />
                      </div>
                    </div>
                    <span class="search-form--row2-filters-kilometers-text">{{
                      item.value
                    }}</span>
                  </div>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              customClass="search-form--huge-dropdown"
              :uppercase="true"
              :title="$t('pages.searchpage.searchform.dropdownYears.title')"
              bgColor="white"
              @close="closeModal"
              id="year-dropdown-panel"
              v-if="usedCars"
            >
              <div class="search-form--row2-filters-kilometers-wrapper">
                <div class="search-form--row2-filters-kilometers">
                  <span class="search-form--row2-filters-kilometers-text">{{
                    $t("pages.searchpage.searchform.dropdownYears.from")
                  }}</span>
                  <div
                    class="search-form--row2-filters-kilometers-item"
                    v-for="(item, index) in yearInfList"
                    :key="index + renderKey"
                    @click="handleYearDropdown(item, 'from')"
                  >
                    <div
                      class="search-form--row2-filters-kilometers-checkbox-wrapper"
                    >
                      <div
                        class="search-form--row2-filters-kilometers-checkbox"
                      >
                        <Icon
                          class="check-filters"
                          color="secondary"
                          name="check"
                          size="x-small"
                          iconPrefix="fa"
                          v-if="item.selected"
                        />
                      </div>
                    </div>
                    <span class="search-form--row2-filters-kilometers-text">{{
                      item.value
                    }}</span>
                  </div>
                </div>
                <div class="search-form--row2-filters-kilometers">
                  <span class="search-form--row2-filters-kilometers-text">{{
                    $t("pages.searchpage.searchform.dropdownYears.to")
                  }}</span>
                  <div
                    class="search-form--row2-filters-kilometers-item"
                    v-for="(item, index) in yearSupList"
                    :key="index + renderKey"
                    @click="handleYearDropdown(item, 'to')"
                  >
                    <div
                      class="search-form--row2-filters-kilometers-checkbox-wrapper"
                    >
                      <div
                        class="search-form--row2-filters-kilometers-checkbox"
                      >
                        <Icon
                          class="check-filters"
                          color="secondary"
                          name="check"
                          size="x-small"
                          iconPrefix="fa"
                          v-if="item.selected"
                        />
                      </div>
                    </div>
                    <span class="search-form--row2-filters-kilometers-text">{{
                      item.value
                    }}</span>
                  </div>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              :uppercase="true"
              bgColor="white"
              :title="$t('pages.searchpage.searchform.dropdownFuel.title')"
              @close="closeModal"
              id="fuel-dropdown-modal"
            >
              <div
                class="menu--item"
                v-for="(item, index) in fuelList"
                :key="index + renderKey"
              >
                <!-- replace with routerlink -->

                <div @click="createTag(item, 'fuel')">
                  <a v-if="!item.selected">
                    {{ item.value }}
                  </a>
                  <a v-if="item.selected" class="selected">
                    {{ item.value }}
                    <Icon
                      class="check"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      color="secondary"
                    />
                  </a>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              :uppercase="true"
              bgColor="white"
              :title="
                $t('pages.searchpage.searchform.dropdownTransmission.title')
              "
              @close="closeModal"
              id="filters-dropdown-modal"
            >
              <div
                class="menu--item"
                v-for="(item, index) in transmissionList"
                :key="index + renderKey"
              >
                <!-- replace with routerlink -->
                <div @click="createTag(item, 'transmission')">
                  <a v-if="!item.selected">
                    {{ item.value }}
                  </a>
                  <a v-else class="selected">
                    {{ item.value }}
                    <Icon
                      class="check"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      color="secondary"
                    />
                  </a>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              class="dropdown-ml"
              customClass="search-form--huge-dropdown"
              :uppercase="true"
              :title="$t('pages.searchpage.searchform.dropdownLocation.title')"
              bgColor="white"
              @close="closeModal"
              id="location-dropdown-panel"
              v-if="usedCars"
            >
              <div
                class="menu--item"
                v-for="(item, index) in locationList"
                :key="index + renderKey"
              >
                <!-- replace with routerlink -->

                <div @click="handleLocation(index)">
                  <a v-if="!item.selected">
                    {{ item.value }}
                  </a>
                  <a v-if="item.selected" class="selected">
                    {{ item.value }}
                    <Icon
                      class="check"
                      name="check"
                      size="x-small"
                      iconPrefix="fa"
                      color="secondary"
                    />
                  </a>
                </div>
              </div>
            </Dropdown>
          </div>
          <div class="results-button-modal">
            <Button
              @onClick="closeFilterSearchModal"
              type="primary"
              uppercase
              :label="'Resultados'"
              size="large"
              :fill="true"
            ></Button>
          </div>
        </div>
      </template>
    </Panel>
  </Block>
</template>

<script>
import { Block, Label, Button, Tag, Icon, Input } from "../../atoms";
import { Dropdown } from "../../molecules";
import { PriceSearchDetails, AutocompleteDropdown } from "../../organisms";
import { mapState, mapGetters } from "vuex";

const DEFAULT_MONTHLY_VALUE = 250;
//const DEFAULT_DEPOSIT_VALUE = 500;
const HIDDEN_TAGS = ["idvc", "externalcolor", "internalcolor"];

const REPLACE_TAGS = ["needle", "price", "monthlyDeposit"];

import { getPriceFormatted } from "../../../utils/currency";

export default {
  components: {
    Block,
    Label,
    Button,
    Dropdown,
    Tag,
    PriceSearchDetails,
    Icon,
    AutocompleteDropdown,
    Input,
    Panel: () => import("../../molecules/Panel/Panel.vue"),
    RightSidebar: () => import("../RightSidebar/RightSidebar.vue")
  },
  data() {
    return {
      completeSearch: {},
      mySearch: "",
      mensalidade: 0,
      entrada: 0,
      minPrice: 0,
      maxPrice: 0,
      minYear: 0,
      maxYear: 0,
      minKm: 0,
      maxKm: 0,
      opened: false,
      saveSearchModalOpened: false,
      myTitleSaveSearch: "",
      includeRetake: false,
      renderComponent: true,
      hasFirstLeftInput: false,
      kmInfList: [],
      kmSupList: [],
      yearInfList: [],
      yearSupList: [],
      locationList: [],
      selectedLocationIds: [],
      renderKey: 0
    };
  },
  props: {
    segmentList: {
      type: Array[Object],
      default: () => []
    },
    modelList: {
      type: Array[Object],
      default: () => []
    },
    fuelList: {
      type: Array[Object],
      default: () => []
    },
    financeProps: {
      type: Object,
      default: () => {}
    },
    transmissionList: {
      type: Array[Object],
      default: () => []
    },
    resumeSearch: {
      type: Array[Object],
      default: () => []
    },
    usedCars: {
      type: Boolean,
      default: false
    },
    yearMin: {
      type: Number,
      default: 1970
    },
    yearMax: {
      type: Number,
      default: new Date().getFullYear()
    },
    kilometersMin: {
      type: Number,
      default: 0
    },
    kilometersMax: {
      type: Number,
      default: 140000
    }
  },
  mounted() {
    this.maxYear = this.yearMax;
    this.minYear = this.yearMin;
    this.minKm = this.kilometersMin;
    this.maxKm = this.kilometersMax;
    this.kmInfList = JSON.parse(JSON.stringify(this.getDropdowns["km"]));
    this.kmSupList = JSON.parse(JSON.stringify(this.getDropdowns["km"]));
    this.yearInfList = JSON.parse(JSON.stringify(this.getDropdowns["year"]));
    this.yearSupList = JSON.parse(JSON.stringify(this.getDropdowns["year"]));
    this.locationList = JSON.parse(
      JSON.stringify(this.getDropdowns["district"])
    );
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    onToggleHandler(event) {
      this.includeRetake = event;
      this.onChangeRangeHandler({
        range: "bottom",
        value: this.currentDeposit
      });
      this.search();
    },
    onStoreResults(autocompleteSearch) {
      this.$store.dispatch("storeAllResults", autocompleteSearch);
    },
    handleSearchButton(e) {
      if (e.value === "") return;
      this.$store.commit("SearchModule/filter/setNeedle", e.value);

      this.createTag(
        {
          key: e.id,
          value: e.value
        },
        e.type
      );
      this.mySearch = "";
    },
    search() {
      // changes the value of the dropdown variable in the AutocompleteDropdown child component
      this.$refs.autocompleteDropdown.dropdown = false;
      this.$refs.autocompleteDropdown.mySearch = "";
      if (this.usedCars) {
        this.$store.dispatch("SearchModule/fetchUsed", {
          quickSearchItem: [],
          dynamic: false
        });
      } else {
        this.$store.dispatch("SearchModule/fetchRecent", {
          quickSearchItem: [],
          dynamic: false
        });
      }
      this.renderKey++;
    },
    saveSearch() {
      this.$emit("saveSearch");

      this.$store.dispatch("SearchModule/requestSaveSearch", {
        title: this.myTitleSaveSearch,
        used: this.usedCars
      });
      this.saveSearchModalOpened = false;
      this.myTitleSaveSearch = "";
    },
    onChangeRangeHandler(event) {
      if (event.range === "top") {
        this.$store.commit("setMonthlyValue", event.value);
        this.mensalidade = event.value;
      } else if (event.range === "bottom") {
        const inStep = event.value;
        this.$store.commit("setDepositValue", inStep);
        this.entrada = inStep;
        this.currentDeposit = inStep;
      }

      if (!this.mensalidade) {
        this.mensalidade = DEFAULT_MONTHLY_VALUE || this.getMonthlyMin;
      }

      if (!this.entrada) {
        this.entrada = this.getDepositMin;
      }
      this.$store.dispatch("SearchModule/filter/removeTagByType", "price");

      this.createTag(
        {
          key: {
            monthlyPayment: `${this.mensalidade}`,
            depositValue: `${this.entrada}`
          },
          value: this.$t("pages.searchpage.searchform.monthlyDeposit", {
            value1: getPriceFormatted(this.mensalidade),
            value2: getPriceFormatted(this.entrada)
          })
        },
        "monthlyDeposit"
      );
    },
    handleLocation(index) {
      if (this.locationList[index].selected) {
        this.locationList[index].selected = false;
        this.locationList[index].key.map(id => {
          this.selectedLocationIds.splice(
            this.selectedLocationIds.indexOf(id),
            1
          );
        });
        this.$store.dispatch("SearchModule/filter/removeTagByTypeValue", {
          type: "DEALER",
          value: this.locationList[index].value
        });
        this.search();
      } else {
        this.locationList[index].selected = true;
        this.selectedLocationIds.push(...this.locationList[index].key);
        this.createTag(
          {
            key: {
              ...this.locationList[index].key
            },
            value: this.locationList[index].value
          },
          "DEALER"
        );
      }
    },
    handleKmDropdown(event, type) {
      if (type == "from") {
        this.kmInfList.map(item => {
          if (item.value == event.value) {
            if (!item.selected) {
              this.minKm = event.value.split(" ")[0];
            } else {
              this.minKm = this.minKilometers;
            }
            item.selected = !item.selected;
          } else {
            item.selected = false;
          }
        });
      } else {
        this.maxKm = event.value;
        this.kmSupList.map(item => {
          if (item.value == event.value) {
            if (!item.selected) {
              this.maxKm = event.value.split(" ")[0];
            } else {
              this.maxKm = this.maxKilometers;
            }
            item.selected = !item.selected;
          } else {
            item.selected = false;
          }
        });
      }
      this.$store.dispatch("SearchModule/filter/removeTagByType", "km");
      this.createTag(
        {
          key: {
            kmInf: Number(this.minKm || this.minKilometers || 0),
            kmSup: Number(this.maxKm)
          },
          value: this.$t("pages.searchpage.searchform.kilometers", {
            value1: this.minKm || 0,
            value2: this.maxKm
          })
        },
        "km"
      );
    },
    handleYearDropdown(event, type) {
      if (type == "from") {
        this.minYear = event.value;
        this.yearInfList.map(item => {
          if (item.value == event.value) {
            item.selected = !item.selected;
          } else {
            item.selected = false;
          }
        });
      } else {
        this.maxYear = event.value;
        this.yearSupList.map(item => {
          if (item.value == event.value) {
            item.selected = !item.selected;
          } else {
            item.selected = false;
          }
        });
      }
      this.$store.dispatch("SearchModule/filter/removeTagByType", "year");
      this.createTag(
        {
          key: { yearInf: `${this.minYear}`, yearSup: `${this.maxYear}` },
          value: this.$t("pages.searchpage.searchform.year", {
            value1: this.minYear,
            value2: this.maxYear
          })
        },
        "year"
      );
    },
    onChangeInputHandler(event, type) {
      if (type == "price") {
        this.maxPrice = event.max;
        this.minPrice = event.min;
        this.$store.dispatch(
          "SearchModule/filter/removeTagByType",
          "monthlyDeposit"
        );

        this.createTag(
          {
            key: { priceInf: `${this.minPrice}`, priceSup: `${this.maxPrice}` },
            value: this.$t("pages.searchpage.searchform.price", {
              value1: getPriceFormatted(this.minPrice),
              value2: getPriceFormatted(this.maxPrice)
            })
          },
          "price"
        );
      } else if (type.includes("year")) {
        if (type == "yearMin") {
          this.minYear = event.target.value;
        } else {
          this.maxYear = event.target.value;
        }
        this.$store.dispatch("SearchModule/filter/removeTagByType", "year");

        this.createTag(
          {
            key: { yearInf: `${this.minYear}`, yearSup: `${this.maxYear}` },
            value: this.$t("pages.searchpage.searchform.year", {
              value1: this.minYear,
              value2: this.maxYear
            })
          },
          "year"
        );
      } else if (type.includes("kilometers")) {
        if (type == "kilometersMin") {
          this.minKm = event.target.value;
        } else {
          this.maxKm = event.target.value;
        }
        this.$store.dispatch("SearchModule/filter/removeTagByType", "km");

        this.createTag(
          {
            key: {
              kilometersInf: `${this.minKm || 0}`,
              kilometersSup: `${this.maxKm}`
            },
            value: this.$t("pages.searchpage.searchform.kilometers", {
              value1: this.minKm || 0,
              value2: this.maxKm
            })
          },
          "km"
        );
      }
    },
    async createTag(item, type) {
      const { key, value } = item;
      let tag = {
        text: value,
        value,
        type,
        id: key
      };
      if (type == "model") {
        tag.imageUrl = item.imageURL;
        tag.count = item.count;
        tag.type = "brands";
      }
      var found = false;
      if (REPLACE_TAGS.includes(tag.type)) {
        this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
      } else
        found = await this.$store.dispatch(
          "SearchModule/filter/addOrRemoveTag",
          tag
        );
      if (type == "model") {
        const tagCopy = JSON.parse(JSON.stringify(tag));
        tagCopy.type = "model";
        this.$store.dispatch("SearchModule/dropdown/updateDropdown", {
          ...tagCopy,
          selected: found == undefined
        });
      } else {
        this.$store.dispatch("SearchModule/dropdown/updateDropdown", {
          ...tag,
          selected: found == undefined
        });
      }
      this.search();
    },
    handleRemoveTag(tag) {
      if (tag.type === "needle") {
        this.$store.commit("SearchModule/filter/setNeedle", "");
      }

      this.$store.dispatch("SearchModule/filter/addOrRemoveTag", tag);
      this.$store.dispatch("SearchModule/dropdown/updateDropdown", {
        ...tag,
        selected: false
      });
      if (tag.type == "km") {
        this.minKm = this.kilometersMin;
        this.maxKm = this.kilometersMax;
        this.kmInfList = JSON.parse(JSON.stringify(this.getDropdowns["km"]));
        this.kmSupList = JSON.parse(JSON.stringify(this.getDropdowns["km"]));
      } else if (tag.type == "year") {
        this.maxYear = this.yearMax;
        this.minYear = this.yearMin;
        this.yearInfList = JSON.parse(
          JSON.stringify(this.getDropdowns["year"])
        );
        this.yearSupList = JSON.parse(
          JSON.stringify(this.getDropdowns["year"])
        );
      } else if (tag.type == "DEALER") {
        const index = this.locationList.findIndex(
          item => item.value == tag.value
        );
        this.locationList[index].selected = false;
        this.locationList[index].key.map(id => {
          this.selectedLocationIds.splice(
            this.selectedLocationIds.indexOf(id),
            1
          );
        });
      }

      this.search();
    },
    onPriceDropdownOpened() {
      if (this.opened) {
        this.opened = !this.opened;
      }
    },
    closeModal() {
      this.opened = false;
      this.$emit("close");
    },
    openSaveSearchModal() {
      if (this.isGuest) {
        return (this.$store.state.isOpenSignupPopup = true);
      }
      this.saveSearchModalOpened = true;

      return false;
    },
    closeSaveSearchModal() {
      this.saveSearchModalOpened = false;
    },
    closeFilterSearchModal() {
      this.$store.commit("closeFilterPanel");
    },
    handleCleanSearch() {
      this.$store.dispatch("SearchModule/filter/cleanAllTags");
      this.$store.dispatch("SearchModule/dropdown/resetDropdowns");
      this.$store.commit("SearchModule/filter/setNeedle", "");

      this.minKm = this.kilometersMin;
      this.maxKm = this.kilometersMax;
      this.kmInfList = JSON.parse(JSON.stringify(this.getDropdowns["km"]));
      this.kmSupList = JSON.parse(JSON.stringify(this.getDropdowns["km"]));
      this.maxYear = this.yearMax;
      this.minYear = this.yearMin;
      this.yearInfList = JSON.parse(JSON.stringify(this.getDropdowns["year"]));
      this.yearSupList = JSON.parse(JSON.stringify(this.getDropdowns["year"]));
      this.locationList.map(item => {
        item.selected = false;
      });
      this.selectedLocationIds = [];
      this.search();
    },
    segmentImage(item) {
      const val = item.value.toLowerCase();
      if (!item.selected) {
        return {
          "segment-suv": val === "suv" || val === "suv / tt",
          "segment-ecological": val === "ecológico",
          "segment-familiar": val === "familiar" || val === "carrinha",
          "segment-utility":
            val === "utilitário" || val === "comercial" || val === "citadino",
          "segment-sporty": val === "desportivo" || val === "hatchback"
        };
      } else {
        return {
          "segment-suv-selected": val === "suv" || val === "suv / tt",
          "segment-ecological-selected": val === "ecológico",
          "segment-familiar-selected": val === "familiar" || val === "carrinha",
          "segment-utility-selected":
            val === "utilitário" || val === "comercial" || val === "citadino",
          "segment-sporty-selected": val === "desportivo" || val === "hatchback"
        };
      }
    },
    multiples(price) {
      // multiples of getMonthlyStep
      const trunc = Math.trunc(price / this.getMonthlyStep);
      return trunc * this.getMonthlyStep;
    },
    generateId(id) {
      return this.usedCars ? id + "-used" : id;
    }
  },
  computed: {
    ...mapState(["isOpenSignupPopup"]),
    ...mapGetters([
      "getAllResults",
      "getAutoCompleteLoadingState",
      "isGuest",
      "getMonthlyValue",
      "getDepositValue",
      "getLastDepositValue",
      "getRetakeObj",
      "getMonthlyStep",
      "getIncludeRetake",
      "getReturnValue",
      "getPagination",
      "isFilterPanelOpened"
    ]),
    ...mapGetters({
      getNeedle: "SearchModule/filter/getNeedle",
      getTags: "SearchModule/filter/getTags",
      getDropdowns: "SearchModule/dropdown/getDropdowns"
    }),
    currentMonthlyValue() {
      return this.getMonthlyValue;
    },
    getMonthlyMin() {
      return Math.floor(this.financeProps.monthlyMin);
    },
    getMonthlyMax() {
      return Math.ceil(this.financeProps.monthlyMax);
    },
    getDepositMin() {
      let depositMin = 0;

      if (this.getIncludeRetake && this.getReturnValue < this.getDepositMax) {
        depositMin =
          this.getReturnValue % 500 === 0
            ? this.getReturnValue
            : Math.ceil(this.getReturnValue / 500) * 500;
      } else {
        depositMin = Math.floor(this.financeProps.depositMin);
      }

      this.forceRerender();
      return depositMin;
    },
    cssClassSearchForm() {
      return this.usedCars ? "search-form-used" : "search-form--row2";
    },
    getDepositMax() {
      return Math.ceil(this.financeProps.depositMax);
    },
    getPriceMin() {
      return Math.floor(this.financeProps.priceMin);
    },
    getPriceMax() {
      return Math.ceil(this.financeProps.priceMax);
    },
    currentDeposit() {
      const val =
        this.getDepositValue || this.getLastDepositValue || this.getDepositMin;
      return Math.max(this.getDepositMin, Math.min(this.getDepositMax, val));
    },
    filterModelList: {
      cache: false,
      get() {
        return this.modelList.filter(i => i.imageURL !== null);
      }
    },
    resumeObj() {
      return this.getRetakeObj;
    },
    cleanTags() {
      return this.getTags?.filter(
        t => !HIDDEN_TAGS.includes(t.type.toLowerCase())
      );
    }
  },
  updated() {
    this.$emit("filterCounter", this.getTags.length);
  }
};
</script>

<style lang="css" scoped src="./SearchForm.css"></style>
